import React, { useRef, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Card } from '../../../components';
import { Button } from '../../../common/elements';
import { uuidv4 } from '../../../utils';
import { GET_SAMPLE_CAPTURES_BY_LAB_NUMBERS } from '../../../graphql/queries/sampleCapture'
import {
  ErrorModal,
  FormInputs,
  LoadingModal,
  PhotoGrid,
  SuccessModal,
  ExistingSampleModal,
  ExistingSampleRangeModal
} from '../components';
import audio from './camera-click.mp3';
import { useCookies } from 'react-cookie';
import { getUserEmail } from '../../../auth/accessToken';

const initialState = {
  fiscalYear: '2024',
  lab: '',
  labNumber: '',
  labNumberEnd: '',
  sampleType: '',
};

const sampleCapQueue = 'https://sample-cap-queue-staging-bjudfyf5za-uc.a.run.app/addSampleCapToRedisQueue';
// const sampleCapQueue = 'http://localhost:4001/addSampleCapToRedisQueue';
const sound = new Audio(audio)

const Capture = ({ handleShowModal }) => {
  const fileInput = useRef();
  const [autoIncrement, setAutoIncrement] = useState(true);
  const [blobs, setBlobs] = useState([]);
  const [errors, setErrors] = useState({});
  const [fileInputKey, setFileInputKey] = useState(1);
  const [formData, setFormData] = useState(initialState);
  const [labLocked, setLabLocked] = useState(false);
  const [labRange, setLabRange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sampleTypeLocked, setSampleTypeLocked] = useState(false);
  const [fiscalYearLocked, setFiscalYearLocked] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [existingSample, setExistingSample] = useState(false);
  const [existingSampleModalOpen, setESMOpen] = useState(false);
  const [numberErrorTap, setNET] = useState(0);
  const [placeHolder, setPlaceHolder] = useState("")
  const [existingPic, setExistingPic] = useState("")
  const [existingSampleID, setExistingSampleID] = useState("")
  const [existingSampleTokens, setExistingSampleTokens] = useState([])
  const [existingSampleIDsRange, setExistingSampleIDsRange] = useState([])
  const [existingRangeSamplesModal, setERSModal] = useState(false);
  const [advanceOptionsLabel, setAdvanceOptionsLabel] = useState("");
  const [advanceOptionsModal, setAdvanceOptionsModal] = useState(false);
  const [showAutoPhotoTools, setShowAutoPhotoTools] = useState(false);
  const [autoPicTimeInterval, setAutoPicTimeInterval] = useState(5);
  const [takingAutoPictures, setTakingAutoPictures] = useState(false);
  const [userEmail, setUsersEmail] = useState("");
  const [cookies, setCookie] = useCookies(['user']);

  const handleCookie = () => {
    setCookie('lab', formData.lab, { path: '/' });
    setCookie('sampleType', formData.sampleType, { path: '/' });
    setCookie('labLocked', labLocked, { path: '/' });
    setCookie('sampleTypeLocked', sampleTypeLocked, { path: '/' });
    setCookie('fiscalYearLocked', fiscalYearLocked, { path: '/' });
    setCookie('autoIncrement', autoIncrement, { path: '/' });
    setCookie('labNumber', formData.labNumber, { path: '/' });
    setCookie('labRange', labRange, { path: '/' });
    setCookie('showAutoPhotoTools', showAutoPhotoTools, { path: '/' });
    setCookie('autoPicTimeInterval', autoPicTimeInterval, { path: '/' });
    setCookie('advanceOptionsLabel', advanceOptionsLabel, { path: '/' });
  }

  const [getSampleCapturesByLabNumbers, { loading: capRangeLoading, data: capRangeData, error: capRangeError }] = useLazyQuery(GET_SAMPLE_CAPTURES_BY_LAB_NUMBERS);
  console.log(capRangeData)

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };
  const webcamRef = React.useRef(null);

  const onChange = e => {
    if (e === "sampleTypeLocked" || e === "labLocked") {
      delete errors[e];
      setErrors(errors);
      setPlaceHolder(formData);
    } else {
      const { name, value } = e.target;
      if (name === 'labNumber' || name === 'labNumberEnd') {
        setFormData({
          ...formData,
          [name]: parseInt(value),
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
      delete errors[name];
      setErrors(errors);
      setPlaceHolder(formData);
    }
  };

  const handlePhotoAddToGrid = (photoString) => {
    //const photob64 = photoString.split("data:image/jpeg;base64,").pop();
    setBlobs([...blobs, {
      id: uuidv4(),
      blob: photoString
    }]);
    delete errors.blobs;
    setErrors(errors);
  }
  const handlePhotoAddToGridFileInput = () => {
    if (
      fileInput.current
      && fileInput.current.files
      && fileInput.current.files.length > 0
    ) {
      const file = fileInput.current.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBlobs([...blobs, {
          id: uuidv4(),
          blob: reader.result,
          file: fileInput.current.files[0],
        }]);
      };

      delete errors.blobs;
      setErrors(errors);
    }
  };

  // const rotateImage = (imageBase64, rotation, cb) => {
  //   const img = new Image();
  //   img.src = imageBase64;
  //   img.onload = () => {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     const ctx = canvas.getContext("2d");
  //     ctx.setTransform(1, 0, 0, 1, img.width / 2, img.height / 2);
  //     ctx.rotate(rotation * (Math.PI / 180));
  //     ctx.drawImage(img, -img.width / 2, -img.height / 2);
  //     cb(canvas.toDataURL("image/jpeg"))
  //   };
  // };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // let sound = new Audio(audio)
    sound.play()
    // if (formData.lab === "Hastings") {
    //   rotateImage(imageSrc, 180, async (url) => {
    //     handlePhotoAddToGrid(url)
    //   })
    // } else {
    handlePhotoAddToGrid(imageSrc)
    // }
  }

  const handlePhotoDelete = blobId => {
    setBlobs([...blobs.filter(el => el.id !== blobId)]);
    setFileInputKey(fileInputKey + 1);
  };

  const handleFullReset = () => {
    setBlobs([]);
    setFileInputKey(fileInputKey + 1);
    setFormData({ ...initialState });
    setAutoIncrement(false);
    setErrors([]);
    setLabLocked(false);
    setLabRange(false);
    setSampleTypeLocked(false);
    setFiscalYearLocked(false);
    setExistingPic("")
    setExistingSampleID("")
    setExistingSampleTokens([])
    setExistingSample(false)
    setNET(0)
    setExistingSampleIDsRange([])
    setTakingAutoPictures(false)
  };

  const handleResetAfterSubmit = () => {
    const getLabNumber = key => {
      if (labRange) {
        return initialState[key];
      }

      if (autoIncrement) {
        if (key === 'labNumber') {
          return Number(formData[key]) + 1;
        }
      }

      return initialState[key];
    };

    setFormData({
      ...formData,
      ...!labLocked && { lab: initialState.lab },
      ...!sampleTypeLocked && { sampleType: initialState.sampleType },
      ...!fiscalYearLocked && { fiscalYear: initialState.fiscalYear },
      labNumber: getLabNumber('labNumber'),
      labNumberEnd: getLabNumber('labNumberEnd'),
    });

    setBlobs([]);
    setErrors({});
    setFileInputKey(fileInputKey + 1);
    setExistingPic("")
    setExistingSampleID("")
    setExistingSampleTokens([])
    setExistingSample(false)
    setNET(0)
    setExistingSampleIDsRange([])
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formErrors = {};

    if (!formData.lab) {
      formErrors.lab = { error: true, message: 'Lab is required' };
    }

    if (!formData.sampleType) {
      formErrors.sampleType = { error: true, message: 'Sample Type is required' };
    }

    if (!formData.fiscalYear) {
      formErrors.fiscalYear = { error: true, message: 'Year is required' };
    }

    if (labRange) {
      if (!formData.labNumber
        || (labRange && !formData.labNumberEnd)
        || (labRange && (Number(formData.labNumber) >= Number(formData.labNumberEnd)))
      ) {
        formErrors.labNumber = { error: true, message: 'Lab Range is invalid' };
        formErrors.labNumberEnd = { error: true };
      }
      else if (((Number(formData.labNumberEnd) - Number(formData.labNumber) > 200))) {
        formErrors.labNumber = { error: true, message: 'Cannot include more than 200 samples in a Range' };
        formErrors.labNumberEnd = { error: true };
      }
    }

    if (!labRange) {
      if (!formData.labNumber) {
        formErrors.labNumber = { error: true, message: 'Lab Number is required' };
      }
    }

    if (blobs.length === 0) {
      formErrors.blobs = { error: true, message: 'At least 1 photo is required' };
    }

    if (Object.keys(formErrors).length > 0) {
      console.log('error', formErrors);
      setShowErrorModal(true);
      setErrors(formErrors);
      return;
    }
    if (existingSample === true && existingSampleIDsRange.length === 0) {
      handleOpenNETModal();
    } else if (existingSample === true && existingSampleIDsRange.length > 0) {
      handleOpenERSModal();
    } else if (existingSample === false) {

      setLoading(true);

      const photos = blobs.map(({ blob }) => blob.split("data:image/jpeg;base64,").pop());
      const variables = { environment: "staging", data: { ...formData, photos, userEmail } };

      await fetch(sampleCapQueue, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

      handleResetAfterSubmit();
      setLoading(false);
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
      }, 4000);
    }
  };

  const handleExistingSampleSubmit = async e => {
    setLoading(true);
    setESMOpen(false)
    setERSModal(false)
    const photos = blobs.map(({ blob }) => blob.split("data:image/jpeg;base64,").pop());
    const variables = { environment: "staging", data: { ...formData, photos, numberErrorTap, existingSampleID, overwrite: false, userEmail } };

    await fetch(sampleCapQueue, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

    if (showAutoPhotoTools === false || (formData.lab !== 'Hastings' && showAutoPhotoTools === true)) {
      handleResetAfterSubmit();
    }

    setLoading(false);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
    if (formData.lab === 'Hastings' && showAutoPhotoTools === true) {
      const currentLabNum = parseInt(formData.labNumber)
      const newLabNumber = currentLabNum + 1;
      setFormData({
        ...formData,
        ...!labLocked && { lab: initialState.lab },
        ...!sampleTypeLocked && { sampleType: initialState.sampleType },
        ...!fiscalYearLocked && { fiscalYear: initialState.fiscalYear },
        labNumber: newLabNumber,
        labNumberEnd: '',
      });

      setTimeout(() => {
        setErrors({});
        setFileInputKey(fileInputKey + 1);
        setExistingPic("")
        setExistingSampleID("")
        setExistingSampleTokens([])
        setExistingSample(false)
        setNET(0)
        setExistingSampleIDsRange([])

        setBlobs([]);
      }, (autoPicTimeInterval - 1) * 1000);
    }
  }

  const handleOverWrite = async e => {
    setLoading(true);
    setESMOpen(false)
    setERSModal(false)
    const photos = blobs.map(({ blob }) => blob.split("data:image/jpeg;base64,").pop());
    let variables;
    if (existingSampleIDsRange.length === 0) {
      variables = { environment: "staging", data: { ...formData, photos, existingSampleID, existingSampleTokens: JSON.stringify(existingSampleTokens), overwrite: true, userEmail } };
    } else {
      variables = { environment: "staging", data: { ...formData, photos, overwrite: true, overwriteWithinRangeIDs: JSON.stringify(existingSampleIDsRange), userEmail } };
    }

    await fetch(sampleCapQueue, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

    if (showAutoPhotoTools === false || (formData.lab !== 'Hastings' && showAutoPhotoTools === true)) {
      handleResetAfterSubmit();
    }

    setLoading(false);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
    if (formData.lab === 'Hastings' && showAutoPhotoTools === true) {
      const currentLabNum = parseInt(formData.labNumber)
      const newLabNumber = currentLabNum + 1;
      setFormData({
        ...formData,
        ...!labLocked && { lab: initialState.lab },
        ...!sampleTypeLocked && { sampleType: initialState.sampleType },
        ...!fiscalYearLocked && { fiscalYear: initialState.fiscalYear },
        labNumber: newLabNumber,
        labNumberEnd: '',
      });

      setTimeout(() => {
        setFileInputKey(fileInputKey + 1);
        setBlobs([]);
      }, (autoPicTimeInterval - 1) * 1000);
    }
  }

  const handleRange = () => {
    setLabRange(!labRange);
    setAutoIncrement(false);

    delete errors.labNumber;
    delete errors.labNumberEnd;
    setErrors(errors);
    setShowErrorModal(false);

    setFormData({
      ...formData,
      labNumber: initialState.labNumber,
      labNumberEnd: initialState.labNumberEnd,
    });
  };

  const startAutoPictures = () => {
    sound.load();
    const formErrors = {};

    if (!formData.lab) {
      formErrors.lab = { error: true, message: 'Lab is required' };
    }

    if (!formData.sampleType) {
      formErrors.sampleType = { error: true, message: 'Sample Type is required' };
    }

    if (!formData.fiscalYear) {
      formErrors.fiscalYear = { error: true, message: 'Year is required' };
    }

    if (!formData.labNumber) {
      formErrors.labNumber = { error: true, message: 'Lab Number is required' };
    }

    if (autoPicTimeInterval < 5) {
      formErrors.interval = { error: true, message: 'Time Interval must be at least 5 seconds' };
    }

    if (!labLocked) {
      formErrors.labLocked = { error: true, message: 'Lab must be locked in Auto Picture mode' };
    }

    if (!sampleTypeLocked) {
      formErrors.sampleTypeLocked = { error: true, message: 'Sample Type must be locked in Auto Picture mode' };
    }


    if (Object.keys(formErrors).length > 0) {
      console.log('error', formErrors);
      setShowErrorModal(true);
      setErrors(formErrors);
      return;
    }

    if (Object.keys(formErrors).length === 0 && autoPicTimeInterval >= 5) {
      setTakingAutoPictures(true)
    } else {
      console.log("missing info or interval < 5")
    }
  }

  const stopAutoPictures = () => {
    setTakingAutoPictures(false)
  }

  const autoSubmitPics = async () => {
    let existing;
    const imageSrc = webcamRef.current.getScreenshot();
    // let sound = new Audio(audio)
    sound.play()
    let photoStringToUse;

    handlePhotoAddToGrid(imageSrc);
    photoStringToUse = imageSrc;

    const existingQuery = `
                {
                    getSampleCapturesByLabNumbers(
                        fiscalYear:${JSON.stringify(formData.fiscalYear)},
                        lab:${JSON.stringify(formData.lab)},
                        sampleType: ${JSON.stringify(formData.sampleType)},
                        labNumber: ${formData.labNumber}
                    ) {
                        ok
                        sampleCaptures{
                            fiscalYear
                            id
                            lab
                            labNumber
                            photos {
                                id
                                token
                                url
                            }
                            sampleType
                        }
                    }
                }
            `;
    const existingFetch = await fetch('https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql', { method: 'POST', body: JSON.stringify({ query: existingQuery }), headers: { 'Content-type': 'application/json' } });
    // const existingFetch = await fetch('http://localhost:4000/graphql', { method: 'POST', body: JSON.stringify({ query: existingQuery }), headers: { 'Content-type': 'application/json' } });
    const existingJson = await existingFetch.json();
    existing = existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures.length;


    if (existing > 0) {
      let eToken = [];
      setExistingSampleID(existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].id)
      existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].photos.forEach(x => {
        eToken.push(x.token)
      })
      setExistingSampleTokens({fiscalYear:existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].fiscalYear, sampleType: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].sampleType, lab: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].lab, labNumber:existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].labNumber, photoTokens: eToken})
      setTakingAutoPictures(false)
      handleOpenNETModal();
    }
    //  else if (existingSample === true && existingSampleIDsRange.length > 0) {
    //   setTakingAutoPictures(false)
    //   handleOpenERSModal();
    // } 
    else {
      let photos = [];
      photos.push(photoStringToUse.split("data:image/jpeg;base64,").pop())
      const variables = { environment: "staging", data: { ...formData, photos, userEmail } };

      await fetch(sampleCapQueue, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

      const currentLabNum = parseInt(formData.labNumber)
      const newLabNumber = currentLabNum + 1;

      setShowSuccess(true);
      setFormData({
        ...formData,
        ...!labLocked && { lab: initialState.lab },
        ...!sampleTypeLocked && { sampleType: initialState.sampleType },
        ...!fiscalYearLocked && { fiscalYear: initialState.fiscalYear },
        labNumber: newLabNumber,
        labNumberEnd: '',
      });

      setTimeout(() => {
        setErrors({});
        setFileInputKey(fileInputKey + 1);
        setNET(0)
        setBlobs([]);
        setShowSuccess(false);
      }, (autoPicTimeInterval - 1) * 1000);
    }

  }

  const handleCloseError = () => setShowErrorModal(false);
  const handleOpenNETModal = () => setESMOpen(true);
  const handleOpenERSModal = () => setERSModal(true);

  const handleAdvanceOptionsLabel = (option) => setAdvanceOptionsLabel(option);

  const handleSetAdvanceOptions = (option) => {
    switch (option) {
      case 'Range':
        handleRange();
        setShowAutoPhotoTools(false);
        setAutoIncrement(false);
        break;

      case 'Auto Pictures':
        setShowAutoPhotoTools(true);
        setAutoIncrement(true);
        setLabRange(false);
        break;

      default:
        // Lab Number Auto Advance
        setAutoIncrement(true);
        setLabRange(false);
        setShowAutoPhotoTools(false);
        break;
    }
  }

  const handleSetAutoPicTimeInterval = (e) => {
    setAutoPicTimeInterval(e.target.value)
  }

  const preventMinus = (e) => {

    if (e.code === 'Minus' || e.which > 57 || e.which === 32) {

      e.preventDefault();

    }


  };

  useEffect(() => {
    if (advanceOptionsLabel === "") {
      setAdvanceOptionsLabel("Lab Number Auto Advance")
    } else {
      setAdvanceOptionsLabel(advanceOptionsLabel)
    }
  }, [advanceOptionsLabel])

  useEffect(() => {
    if (formData !== "" && formData !== placeHolder) {
      let variables = {};
      if (formData.labNumber === "" || (formData.labNumberEnd === "" && formData.labNumber === "")) {
        variables = {};
      } else if (formData.labNumberEnd !== "" && formData.labNumber !== "" && (formData.labNumberEnd > formData.labNumber)) {
        variables = {
          fiscalYear: formData.fiscalYear,
          lab: formData.lab,
          labNumber: parseInt(formData.labNumber),
          sampleType: formData.sampleType,
          labNumberEnd: parseInt(formData.labNumberEnd)
        };
      } else if (formData.labNumberEnd === "" && formData.labNumber !== "") {
        variables = {
          fiscalYear: formData.fiscalYear,
          lab: formData.lab,
          labNumber: parseInt(formData.labNumber),
          sampleType: formData.sampleType
        };
      }

      if (variables && variables.fiscalYear) {
        getSampleCapturesByLabNumbers({ variables });
      }
    }
  }, [formData, getSampleCapturesByLabNumbers, placeHolder])

  useEffect(() => {
    if (capRangeData && !capRangeLoading && !capRangeError) {
      if (capRangeData.getSampleCapturesByLabNumbers.sampleCaptures.length === 0) {
        setExistingPic("")
      } else if (capRangeData.getSampleCapturesByLabNumbers.sampleCaptures.length === 1 && (formData.labNumberEnd === '' || formData.labNumberEnd === null)) {
        setExistingPic(capRangeData.getSampleCapturesByLabNumbers.sampleCaptures[0]);
      } else if (capRangeData.getSampleCapturesByLabNumbers.sampleCaptures.length > 1 && formData.labNumberEnd !== '') {
        setExistingPic("")
        let newRange = [];
        capRangeData.getSampleCapturesByLabNumbers.sampleCaptures.forEach(x => {
          let photos = [];
          x.photos.forEach(y => {
            photos.push({ "token": y.token })
          })
          newRange.push({ "id": x.id, "sampleType": x.sampleType, "lab": x.lab, "labNumber": x.labNumber, "fiscalYear": x.fiscalYear, "photoTokens": photos })
        })
        setExistingSampleIDsRange(newRange);
      }
    }
  }, [capRangeData, capRangeLoading, capRangeError, formData])

  useEffect(() => {
    if (existingPic !== "" && existingSampleIDsRange.length === 0) {
      if (existingPic && existingPic.photos !== null) {
        setNET(existingPic.photos.length);
        setExistingSample(true)
        setExistingSampleID(existingPic.id)
        let tokens = [];
        existingPic.photos.forEach(x => {
          tokens.push(x.token)
        })
        setExistingSampleTokens({ fiscalYear: existingPic.fiscalYear, sampleType: existingPic.sampleType, lab: existingPic.lab, labNumber: existingPic.labNumber, photoTokens: tokens });
      } else if (existingPic && existingPic.photos === null) {
        setExistingSampleID("")
        setExistingSampleTokens([])
        setExistingSample(false)
        setNET(0)
      } else {
        setExistingSampleID("")
        setExistingSampleTokens([])
        setExistingSample(false)
        setNET(0)
      }
    } else if (existingSampleIDsRange.length > 0 && existingPic === "") {
      setExistingSample(true)
    } else {
      setExistingSampleID("")
      setExistingSampleTokens([])
      setExistingSample(false)
      setNET(0)
    }
  }, [existingPic, existingSampleIDsRange])

  useEffect(() => {
    if (takingAutoPictures === true && blobs.length === 0) {
      const interval = setInterval(async () => {
        autoSubmitPics();
      }, 1000)

      return () => clearInterval(interval);

    }
    // eslint-disable-next-line
  }, [takingAutoPictures, formData, blobs])

  useEffect(() => {
    if (cookies.lab && cookies.sampleType && cookies.labLocked && cookies.sampleTypeLocked && cookies.fiscalYearLocked && cookies.labNumber && cookies.labRange
      && cookies.showAutoPhotoTools && cookies.autoIncrement && cookies.autoPicTimeInterval && cookies.advanceOptionsLabel) {
      setFormData((prevState) => {
        return { ...prevState, lab: cookies.lab, sampleType: cookies.sampleType, labNumber: cookies.labNumber }
      });
      if (cookies.labLocked === "false") {
        setLabLocked(false);
      } else {
        setLabLocked(true);
      }
      if (cookies.sampleTypeLocked === "false") {
        setSampleTypeLocked(false);
      } else {
        setSampleTypeLocked(true);
      }
      if (cookies.fiscalYearLocked === "false") {
        setFiscalYearLocked(false);
      } else {
        setFiscalYearLocked(true);
      }
      if (cookies.labRange === "false") {
        setLabRange(false);
      } else {
        setLabRange(true);
      }
      if (cookies.showAutoPhotoTools === "false") {
        setShowAutoPhotoTools(false);
      } else {
        setShowAutoPhotoTools(true);
        setFormData((prevState) => {
          return { ...prevState, labNumber: parseInt(cookies.labNumber) }
        });
      }
      if (cookies.autoIncrement === "false") {
        setAutoIncrement(false);
      } else {
        setAutoIncrement(true);
      }
      setAutoPicTimeInterval(cookies.autoPicTimeInterval);
      setAdvanceOptionsLabel(cookies.advanceOptionsLabel)
    }
    const email = getUserEmail();
    setUsersEmail(email);
    // eslint-disable-next-line 
  }, [])


  return (
    <>
      {loading && <LoadingModal />}
      {showSuccess && <SuccessModal message="Submitted" />}
      {showErrorModal && Object.keys(errors).length > 0 && (
        <ErrorModal
          errors={errors}
          handleCloseError={handleCloseError}
        />
      )}
      <form
        className="sample-capture-action-wrapper"
        onSubmit={e => { handleSubmit(e); handleCookie() }}
        encType={'multipart/form-data'}
      >
        <Card>
          <ExistingSampleModal
            isOpen={existingSampleModalOpen}
            setModalOpen={setESMOpen}
            handleSubmit={handleExistingSampleSubmit}
            handleOverWrite={handleOverWrite}
          />
          <ExistingSampleRangeModal
            isOpen={existingRangeSamplesModal}
            setModalOpen={setERSModal}
            handleOverWrite={handleOverWrite}
          />
          <FormInputs
            errors={errors}
            formData={formData}
            onChange={onChange}
            withLocksAndRange={{
              autoIncrement: {
                // setter: setAutoIncrement,
                value: autoIncrement,
              },
              labLocked: {
                setter: setLabLocked,
                value: labLocked,
              },
              labRange: {
                // setter: handleRange,
                value: labRange,
              },
              sampleTypeLocked: {
                setter: setSampleTypeLocked,
                value: sampleTypeLocked,
              },
              fiscalYearLocked: {
                setter: setFiscalYearLocked,
                value: fiscalYearLocked,
              },
            }}
            handleAdvanceOptionsLabel={handleAdvanceOptionsLabel}
            advanceOptionsLabel={advanceOptionsLabel}
            advanceOptionsModal={advanceOptionsModal}
            setAdvanceOptionsModal={setAdvanceOptionsModal}
            handleSetAdvanceOptions={handleSetAdvanceOptions}
          />
          <div className="divider" />

          {/* Start, stop, interval for auto pictures */}
          {showAutoPhotoTools &&
            <div className="auto-photo-controls-div">
              <span className='seconds-input '>
                <label className='seconds-label'>seconds</label>
                <input className="auto-control" type='number' min='5' defaultValue={autoPicTimeInterval} onKeyPress={preventMinus} onChange={(e) => handleSetAutoPicTimeInterval(e)}></input>

              </span>

              {takingAutoPictures &&
                <>
                  <Button className='auto-control secondary'>Start</Button>
                  <Button className='auto-control red' onClick={() => { stopAutoPictures(); handleCookie() }}>Stop</Button>
                </>
              }
              {!takingAutoPictures &&
                <>
                  <Button className='auto-control green' onClick={() => startAutoPictures()}>Start</Button>
                  <Button className='auto-control secondary' >Stop</Button>
                </>
              }
            </div>
          }

          <PhotoGrid
            blobs={blobs}
            errors={errors}
            handlePhotoDelete={handlePhotoDelete}
            handlePhotoEnlarge={handleShowModal}
            fileInput={fileInput}
            fileInputKey={fileInputKey}
            videoConstraints={videoConstraints}
            webcamRef={webcamRef}
            showAutoPhotoTools={showAutoPhotoTools}
            handlePhotoAddToGridFileInput={handlePhotoAddToGridFileInput}
          />
        </Card>
        <div className="sample-capture-button-container">
          <div className="sample-capture-buttons">
            <Button onClick={() => handleFullReset()}>Reset</Button>
            <Button buttonType="submit" type="primary" onClick={handleCookie()}>Submit</Button>
            {showAutoPhotoTools &&
              <Button className="green capture-image-button" onClick={() => capture()}>Capture Image</Button>
            }
          </div>
        </div>
      </form>
    </>
  );
};

export default Capture;

Capture.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
};
